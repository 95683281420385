import React ,{useState,useEffect} from "react";
import ChangeDetailsItem from "../change-details-item";
import "./style.scss";

const AddressList = ({setDisplay,address,displayScreen,setAddressDetails,onBack}) => {

  const [addressArray, setAddressArray] = useState([])

  useEffect(() => {

    
    if(address.length>0){

      setAddressArray(address.map(add => (
        <ChangeDetailsItem 
        key={add._id}
        address={add}
        setDisplay={setDisplay}
        displayScreen={displayScreen}
        setAddressDetails={setAddressDetails}
        />
      )))
    }
    
  }, [address])
  

  return (
    <>
      <div className="all-items justify-item">
        <div>
          {addressArray}
          <div className="cart-add-new cursor">
            <img src="/images/common/cart-plus.svg" />
            <div onClick={() => { setDisplay(displayScreen); setAddressDetails({});}}>ADD A NEW ADDRESS</div>
          </div>
        </div>
        <button className="pay" onClick={onBack.bind(null)}>Use this address</button>
      </div>
    </>
  );
};

export default AddressList;
