import React, { useState, useEffect } from "react";
import ChangeDetailsItem from "../change-details-item";
import "./style.scss";
import { Formik } from "formik";
import Input from "../../../../input";
import { connect } from "react-redux";
import SelectInput from "../../../../select";
import allCities from "../../../onboardingCard/brandLocation/cities";
import { updateAddressHandler } from "../../../../../actions/brand/action";
import { updateUserDetailsHandler } from "../../../../../actions/user/action";

import Toaster from "../../../../common/Toaster";
import Loader from "../../../../common/Loader";


const AddressDetails = ({
  setDisplay,
  state,
  addressDetails,
  states,
  updateAddressHandler,
  updateUserDetailsHandler,
  display,
  onBack
}) => {
  const [formikValues, setFormikvalues] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
  });
  const [renderFormik, setRenderFormik] = useState(false);
  const [cities, setCities] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (addressDetails && addressDetails.address) {
      setFormikvalues({
        address: addressDetails.address,
        suite: addressDetails.suite,
        city: addressDetails.city,
        state: addressDetails.state,
        zip: addressDetails.zip,
      });

      setCities(
        allCities[
          states.filter(function (option) {
            return option.value === addressDetails.state;
          })[0].label
        ].map((e) => {
          return { value: e, label: e };
        })
      );
    }
    setRenderFormik(true);
  }, [addressDetails]);

  const setCitiesHandler = (state) => {
    setCities(
      allCities[state.label].map((e) => {
        return { value: e, label: e };
      })
    );
  };

  return (
    <div className="all-items details">
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <Loader isLoading={loading} />
      {renderFormik && (
        <Formik
          initialValues={{ ...formikValues }}
          enableReinitialze={true}
          validate={(values) => {
            const errors = {};

            if (!values.address) {
              errors.address = "Please enter Address!";
            }
            if (!values.city) {
              errors.city = "Please enter City!";
            }
            if (!values.state) {
              errors.state = "Please enter State!";
            }
            if (!values.zip) {
              errors.zip = "Please enter Zip Code!";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            setLoading(true)
            let obj = values;
            if (addressDetails && addressDetails.address) {
              obj = { ...values, _id: addressDetails._id };
            }
            obj.userId = localStorage.getItem("userId");
            obj.type = (display === "addBilling" ? "billing" : "shipping");
            var updateAddress = await updateAddressHandler(obj);

            if(updateAddress.responseData.shippingError && updateAddress.responseData.shippingError[0]){
              setToastrMsg("Could not find this Address.");
              doRefresh((prev) => prev + 1);
              setLoading(false);
            }
            else if (updateAddress && !updateAddress.error && updateAddress.responseData.address.length > 0) {
              
              let userDetailsTemp = {...state}
              let response = {...updateAddress.responseData.address[0]}
              

              if(userDetailsTemp.userDetails.billingAddress.find(e=>e._id===response._id)){
                response.display = userDetailsTemp.userDetails.billingAddress[userDetailsTemp.userDetails.billingAddress.findIndex(e=>e._id===response._id)].display
                userDetailsTemp.userDetails.billingAddress[userDetailsTemp.userDetails.billingAddress.findIndex(e=>e._id===response._id)] = response
                
              }
              else if(userDetailsTemp.userDetails.shippingAddress.find(e=>e._id===response._id)){
                response.display = userDetailsTemp.userDetails.shippingAddress[userDetailsTemp.userDetails.shippingAddress.findIndex(e=>e._id===response._id)].display
                userDetailsTemp.userDetails.shippingAddress[userDetailsTemp.userDetails.shippingAddress.findIndex(e=>e._id===response._id)] = response
              }
              else if(display === "addBilling"){
                response.display = false;

                let tempBilling= userDetailsTemp.userDetails.billingAddress.map((e) => ({
                  ...e,
                  display: false,
                }));
                tempBilling.push(response)
                tempBilling.find((e) => e.default === true).display = true;
                userDetailsTemp.userDetails.billingAddress = tempBilling;
                
                // userDetailsTemp.userDetails.billingAddress.push(response)
              }
              else{
                response.display = false;
                let tempShipping = userDetailsTemp.userDetails.shippingAddress.map((e) => ({
                  ...e,
                  display: false,
                }));
                
                tempShipping.push(response)
                tempShipping.find((e) => e.default === true).display = true;
                userDetailsTemp.userDetails.shippingAddress = tempShipping;


                if(userDetailsTemp.userDetails.billingAddress.length <= 0){
                  let tempBilling= userDetailsTemp.userDetails.billingAddress.map((e) => ({
                    ...e,
                    display: false,
                  }));
                  tempBilling.push(response)
                  tempBilling.find((e) => e.default === true).display = true;
                  userDetailsTemp.userDetails.billingAddress = tempBilling;
                }

                // userDetailsTemp.userDetails.shippingAddress.push(response)
              }

              // console.log(first)

                await updateUserDetailsHandler(userDetailsTemp)
                setLoading(false);
              onBack();
            } 
            else {
              setToastrMsg("Unable to add Brand Location");
              doRefresh((prev) => prev + 1);
              setLoading(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="form-register mt_25_detail"
            >
              <>
                <div className="row ">
                  <div className="col-12 input-container">
                    <div className="input-text">Street Number and Name</div>
                    <div className="details-border street">
                      <Input
                        name="address"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.address && touched.address && (
                      <p className="error-class">{errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-12 input-container">
                    <div className="input-text">Suite Number</div>
                    <div className="details-border suite">
                      <Input
                        name="suite"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.suite}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-6 input-container z-index-3">
                    <div className="input-text">State</div>
                    <div className="details-border x-small">
                      <SelectInput
                        options={states}
                        placeholder={
                          <div className="select-placeholder-text">State</div>
                        }
                        defaultValue={states[0]}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("state", e.value);
                          setFieldValue("city", "");
                          setCitiesHandler(e);
                        }}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                            zIndex: "99",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                          }),
                        }}
                        value={states.filter(function (option) {
                          return option.value === values.state;
                        })}
                      />
                    </div>
                    {errors.state && touched.state && (
                      <p className="error-class">Please enter State</p>
                    )}
                  </div>
                  {/* <div className="col-6 input-container z-index-3">
                    <div className="input-text">City</div>
                    <div className="details-border x-small">
                      <SelectInput
                        options={cities}
                        placeholder={
                          <div className="select-placeholder-text">City</div>
                        }
                        defaultValue={cities[0]}
                        onBlur={handleBlur}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                          }),
                        }}
                        onChange={(e) => {
                          setFieldValue("city", e.value);
                        }}
                        value={cities.filter(function (option) {
                          return option.value === values.city;
                        })}
                      />
                    </div>
                    {errors.city && touched.city && (
                      <p className="error-class">Please choose a City!</p>
                    )}
                  </div> */}

                  <div className="col-6 input-container z-index-3">
                    <div className="input-text">City</div>
                    <div className="details-border x-small">
                      <Input
                        name="city"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.city && touched.city && (
                      <p className="error-class">{errors.city}</p>
                    )}
                  </div>


                </div>
                <div className="row mt-30">
                  <div className="col-6 input-container">
                    <div className="input-text">Zip Code</div>
                    <div className="details-border small">
                      <Input
                        name="zip"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.zip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.zip && touched.zip && (
                      <p className="error-class">{errors.zip}</p>
                    )}
                  </div>
                  <div className="col-6 input-container">
                    <div className="input-text">Country</div>
                    <div className="details-border country">
                      <SelectInput
                        options={[{ value: "US", label: "Unites States" }]}
                        placeholder="Country"
                        defaultValue={{ value: "US", label: "Unites States" }}
                        onBlur={handleBlur}
                        isDisabled = {true}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: "#004e93",
                          }),
                        }}
                        value={{ value: "US", label: "Unites States" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="done">
                  <button>Done!</button>
                </div>
              </>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, { updateAddressHandler,updateUserDetailsHandler })(
  AddressDetails
);
