import React, { useState } from "react";
import AllDetailsItem from "../all-details-item";
import "./style.scss";
import { Fragment } from "react";
import ChangeDetailsItem from "../change-details-item";
import PersonalDetails from "../personal-details";
import { connect } from "react-redux";
import { useEffect } from "react";
import { navigate } from "gatsby";
import AddressList from "../address-list";
import AddressDetails from "../address-details";
import {
  getStatesHandler,
  updateUserDetailsHandler,
  transferACHHandler,
} from "../../../../../actions/user/action";
import PaymentCard from "../../../payment";
import Input from "../../../../input";
import { purchaseProducts } from "../../../../../actions/product/action";
import PaymentBankDetails from "../payment-bank-details";
import Loader from "../../../../common/Loader";
import Toaster from "../../../../common/Toaster";
import ConfirmACHModal from "../confirm-ach";
import { common } from "../../../../../util/constants";
const _ = require("lodash");

const AllDetails = ({
  backbuttonHandler,
  state,
  getStatesHandler,
  updateUserDetailsHandler,
  transferACHHandler,
  purchaseProducts,
  doRefreshDetails,
}) => {
  const [display, setDisplay] = useState("allDetails");
  const [addressDetails, setAddressDetails] = useState({});
  const [shippingLine1, setShippingLine1] = useState("");
  const [shippingLine2, setShippingLine2] = useState("");
  const [billingLine1, setBillingLine1] = useState("");
  const [billingLine2, setBillingLine2] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [taxTotalRef, setTaxTotalRef] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [ein, setEin] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showconfirmACH, setShowconfirmACH] = useState(false);
  const [achDetails, setAchDetails] = useState({});

  const [states, setStates] = useState([]);
  const [back, setBack] = useState(0);
  const [cardPopUp, setCardPopUp] = useState(false);

  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [hasDeletedProduct, setDeletedProduct] = useState(true);
  const [pickupState, setPickupState] = useState(false);

  // const [timedOutStock, setTimedOutStock] = useState(true);

  const checkTimedOutStock = () => {
    let tempStockState = false;

    if (
      _.get(state, "userDetails.cartData.pendingCartProducts") &&
      state.userDetails.cartData.pendingCartProducts.length > 0
    ) {
      for (let pp of state.userDetails.cartData.pendingCartProducts) {
        if (pp.stock) {
          let orderDate = new Date(pp.createdAt);
          let currentDate = new Date();
          const orderDatePlusExtraTime = new Date(pp.createdAt);
          orderDatePlusExtraTime.setMinutes(
            orderDate.getMinutes() + common.CART_EMPTY_TIME
          );
          if (currentDate > orderDatePlusExtraTime) {
            tempStockState = true;
          }
        }
      }
    }

    return tempStockState;
  };

  useEffect(() => {
    //checking if any deleted product is there

    if (
      _.get(state, "userDetails.cartData.pendingCartProducts") &&
      state.userDetails.cartData.pendingCartProducts.length > 0
    ) {
      let tempState = false;
      // let tempStockState = false;
      for (let pp of state.userDetails.cartData.pendingCartProducts) {
        if (pp.product.status === "deleted" || !pp.product.productCategory || (pp.product.productCategory).toLowerCase()!=='streetwear') {
          tempState = true;
        }
        // else if (pp.stock) {
        //   let orderDate = new Date(pp.createdAt);
        //   let currentDate = new Date();
        //   const orderDatePlusExtraTime = new Date(pp.createdAt);
        //   orderDatePlusExtraTime.setMinutes(
        //     orderDate.getMinutes() + common.CART_EMPTY_TIME
        //   );
        //   if (currentDate > orderDatePlusExtraTime) {
        //     tempStockState = true;
        //   }
        // }
      }
      // setTimedOutStock(tempStockState);
      setDeletedProduct(tempState);
    }

    //setting shipping
    if (
      state.userDetails &&
      state.userDetails.shippingAddress &&
      state.userDetails.shippingAddress.length > 0
    ) {
      let defaultshippingAddress = state.userDetails.shippingAddress.find(
        (add) => add.display === true
      );
      setShippingLine1(
        `${defaultshippingAddress.address} ${
          defaultshippingAddress.suite &&
          `Suite#${defaultshippingAddress.suite}`
        }`
      );
      setShippingLine2(
        `${defaultshippingAddress.city} ${defaultshippingAddress.state} (${defaultshippingAddress.zip})`
      );
    }

    //setting billing
    if (
      state.userDetails &&
      state.userDetails.billingAddress &&
      state.userDetails.billingAddress.length > 0
    ) {
      let defaultbillingAddress = state.userDetails.billingAddress.find(
        (add) => add.display === true
      );
      setBillingLine1(
        `${defaultbillingAddress.address} ${
          defaultbillingAddress.suite && `Suite#${defaultbillingAddress.suite}`
        }`
      );
      setBillingLine2(
        `${defaultbillingAddress.city} ${defaultbillingAddress.state} (${defaultbillingAddress.zip})`
      );
    }

    let cartTotal = 0;

    //setting cart
    if (state.userDetails && state.userDetails.cartData) {
      let total =
        state.userDetails.cartData.pendingCartProducts.reduce(
          (acc, curr) => (acc += curr.totalPrice),
          0
        ) || 0;
      cartTotal = total;

      setTotalPrice(total.toFixed(2));
    }

    //setting tax and shipment
    if (state.userDetails && state.userDetails.taxAndShipment) {
      let shippingSum = state.userDetails.taxAndShipment.shippingSum.toFixed(2);
      let taxSum = (state.userDetails.taxAndShipment.tax * cartTotal).toFixed(
        2
      );
      let grandTotalSum = 0;
      if (state.userDetails.selfPickup || pickupState) {
        shippingSum = 0;
      }
      setShippingTotal(shippingSum);
      if (!state.userDetails.ein) {
        setTaxTotal(taxSum);
        grandTotalSum = (+cartTotal + +shippingSum + +taxSum).toFixed(2);
      } else {
        setTaxTotal("");
        grandTotalSum = (+cartTotal + +shippingSum).toFixed(2);
      }
      setTaxTotalRef(taxSum);
      setGrandTotal(grandTotalSum);
    }
  }, [state, pickupState]);

  useEffect(async () => {
    let states = await getStatesHandler();
    if (states.error === false && states.responseData.length > 0) {
      setStates(
        states.responseData.map((e) => {
          return { value: e.abbreviation, label: e.name };
        })
      );
    }
  }, []);

  useEffect(() => {
    if (backbuttonHandler > 0 || back > 0) {
      if (display === "allDetails") {
        navigate("/products/cart");
      } else if (
        display === "addPD" ||
        display === "shippingAddresses" ||
        display === "billingAddresses"
      ) {
        setDisplay("allDetails");
      } else if (display === "addShipping") {
        setDisplay("shippingAddresses");
      } else if (display === "addBilling") {
        setDisplay("billingAddresses");
      }
    }
  }, [backbuttonHandler, back]);

  const backClickHandler = () => {
    setBack((prev) => prev + 1);
  };

  const changeModalState = () => {
    setShowModal((prevState) => {
      return !prevState;
    });
    doRefreshDetails();
  };

  const changeAchDetailsState = () => {
    setShowconfirmACH((prevState) => {
      return !prevState;
    });
  };

  const confirmAchDetails = () => {
    if (checkTimedOutStock()) {
      setToastrMsg("Please refresh page!");
      doRefresh((prev) => prev + 1);
      return;
    }
    setShowconfirmACH(false);
    createACHSourceHandlerFunction();
  };

  const setEinHandler = (e) => {
    let val = e.target.value;

    setEin(val);

    if (val && /^[A-ZA-Z0-9-]{1,20}$/i.test(val)) {
      let grandTotalSum = (+totalPrice + +shippingTotal).toFixed(2);
      setGrandTotal(grandTotalSum);
      setTaxTotal("");
      updateState(val);
    } else {
      let grandTotalSum = (+totalPrice + +shippingTotal + +taxTotalRef).toFixed(
        2
      );
      setGrandTotal(grandTotalSum);
      setTaxTotal(taxTotalRef);
    }
  };

  const updateState = (ein) => {
    let userDetailsTemp = { ...state };
    userDetailsTemp.userDetails.ein = ein;
    updateUserDetailsHandler(userDetailsTemp);
  };

  const createACHSourceHandler = () => {
    if (hasDeletedProduct) {
      setToastrMsg("Please remove discontinued Items from cart!");
      doRefresh((prev) => prev + 1);
      return;
    } else if (checkTimedOutStock()) {
      setToastrMsg("Please refresh page!");
      doRefresh((prev) => prev + 1);
      return;
    } else if (ein !== "" && !/^[A-ZA-Z0-9-]{1,20}$/i.test(ein)) {
      setToastrMsg(
        "EIN number is incorrect. Only numbers, characters and dash allowed!"
      );
      doRefresh((prev) => prev + 1);
    } else {
      setShowconfirmACH(true);
    }
  };

  const openACHPage = () =>{
    navigate("/checkout/plaid");
  }

  const createACHSourceHandlerFunction = async () => {
    if (
      state.userDetails.user.email &&
      state.userDetails.shippingAddress.length > 0 &&
      state.userDetails.billingAddress.length > 0
    ) {
      let achSourceResponse = await transferACHHandler({
        email: state.userDetails.user.email,
      });
      // console.log(achSourceResponse);
      if (
        achSourceResponse.error === false &&
        achSourceResponse.responseData.data &&
        achSourceResponse.responseData.data.id
      ) {
        const chosenShippingAddress = state.userDetails.shippingAddress.find(
          (e) => e.display === true
        );
        const chosenBillingAddress = state.userDetails.billingAddress.find(
          (e) => e.display === true
        );

        const userDetails = {
          name: state.userDetails.user.name,
          email: state.userDetails.user.email,
          phoneNumber: state.userDetails.user.phone,
          userId: chosenShippingAddress.userId,
          brandId: state.userDetails.user.brand,
        };
        const shippingAddressDetails = {
          address: chosenShippingAddress.address,
          city: chosenShippingAddress.city,
          shippoId: chosenShippingAddress.shippoId,
          state: chosenShippingAddress.state,
          suite: chosenShippingAddress.suite,
          zip: chosenShippingAddress.zip,
          referenceId: chosenShippingAddress._id,
        };
        const billingAddressDetails = {
          address: chosenBillingAddress.address,
          city: chosenBillingAddress.city,
          shippoId: chosenBillingAddress.shippoId,
          state: chosenBillingAddress.state,
          suite: chosenBillingAddress.suite,
          zip: chosenBillingAddress.zip,
          referenceId: chosenBillingAddress._id,
        };

        const obj = {
          // ...token,,
          token: { id: null },
          einNumber: state.userDetails.ein ? state.userDetails.ein : "",
          userDetails,
          shippingAddressDetails,
          billingAddressDetails,
          payment_source: "ach",
          source: achSourceResponse.responseData.data.id,
          selfPickup:(state.userDetails && state.userDetails.selfPickup) ? true : false
        };

        setShowModal(true);
        setAchDetails(achSourceResponse.responseData.data);

        // console.log(obj);

        const purchase = await purchaseProducts(obj);
        // navigate("/checkout/thank-you");
      }
    }
  };

  const setCarhPreHandler = () => {
    if (hasDeletedProduct) {
      setToastrMsg("Please remove discontinued Items from cart!");
      doRefresh((prev) => prev + 1);
      return;
    } else if (checkTimedOutStock()) {
      setToastrMsg("Please refresh page!");
      doRefresh((prev) => prev + 1);
      return;
    } else if (ein !== "" && !/^[A-ZA-Z0-9-]{1,20}$/i.test(ein)) {
      setToastrMsg(
        "EIN number is incorrect. Only numbers, characters and dash allowed!"
      );
      doRefresh((prev) => prev + 1);
    } else {
      setCardPopUp(true);
    }
  };

  const setSelfPickup = () => {
    let userDetailsTemp = { ...state };

    if (userDetailsTemp.userDetails.selfPickup) {
      userDetailsTemp.userDetails.selfPickup = false;
      setPickupState(false);
    } else {
      userDetailsTemp.userDetails.selfPickup = true;
      setPickupState(true);
    }

    updateUserDetailsHandler(userDetailsTemp);
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      {cardPopUp && (
        <div className="payment-popup">
          <PaymentCard
            modalState={cardPopUp}
            changeModalState={() => {
              setCardPopUp(false);
            }}
            checkTimedOutStock={checkTimedOutStock}
          />
        </div>
      )}
      <ConfirmACHModal
        showModal={showconfirmACH}
        changeModalState={changeAchDetailsState}
        confirmModalState={confirmAchDetails}
      />
      <PaymentBankDetails
        achDetails={achDetails}
        showModal={showModal}
        amount={grandTotal}
        changeModalState={changeModalState}
      />

      {display === "allDetails" && (
        <div className="all-items">
          {state.userDetails && state.userDetails.user && (
            <AllDetailsItem
              line1="Personal detail"
              line2={state.userDetails.user.name}
              line3={state.userDetails.user.phone}
              popup="addPD"
              setDisplay={setDisplay}
            />
          )}
          <AllDetailsItem
            line1="Shipping detail"
            line2={shippingLine1}
            line3={shippingLine2}
            popup="shippingAddresses"
            setDisplay={setDisplay}
          />
            <div className="summary-item row summary-extras pickup-top">
            <div
              className="col-8 col-md-9 checkbox-col"
              onClick={setSelfPickup}
            >
              <input
                type="checkbox"
                id="self-pickup"
                name="self-pickup"
                value="self-pickup"
                className="pickup-checkbox"
                checked={state.userDetails && state.userDetails.selfPickup}
              />
              Self Pickup
            </div>
          </div>
          <AllDetailsItem
            line1="Billing detail"
            line2={billingLine1}
            line3={billingLine2}
            popup="billingAddresses"
            setDisplay={setDisplay}
          />
          {/* <AllDetailsItem
            line1="Payment detail"
            line2="****2345"
            line3="EXP. 11  2030"
            popup="allDetails"
            setDisplay={setDisplay}
          /> */}
          {/* <div className="line"></div> */}
          <div className="summary-heading">Summary</div>
          <div className="summary-item row summary-extras">
            <div className="col-8 col-md-9">Subtotal</div>
            <div className="col-4 col-md-3 bold">
              ${totalPrice ? totalPrice.split(".")[0] : "00"}.
              <span className="decimal">
                {totalPrice ? totalPrice.split(".")[1] : "00"}
              </span>
            </div>
          </div>
          <div className="summary-item row summary-extras">
            <div className="col-8 col-md-9">
              Tax
              <Input
                name="ein"
                type="text"
                placeholder="Type sellers permit if any"
                className={`sellers-permit ${
                  ein !== "" && !/^[A-ZA-Z0-9-]{1,20}$/i.test(ein) && "red"
                }`}
                value={ein}
                onChange={setEinHandler}
              />
              <span className="falsify">
                <img src="/images/common/tax-icon.svg" />
                Falsifing permit is a federal offence.{" "}
              </span>
            </div>
            <div className="col-4 col-md-3">
              <span className="bold">
                ${taxTotal ? taxTotal.split(".")[0] : "00"}.
              </span>
              <span className="decimal">
                {taxTotal ? taxTotal.split(".")[1] : "00"}
              </span>
            </div>
          </div>
          <div className="summary-item row summary-extras">
            <div className="col-8 col-md-9">shipping</div>
            <div className="col-4 col-md-3">
              <span className="bold">
                ${shippingTotal ? shippingTotal.split(".")[0] : "00"}.
              </span>
              <span className="decimal">
                {shippingTotal ? shippingTotal.split(".")[1] : "00"}
              </span>
            </div>
          </div>
        
          <div className="line"></div>

          <div className="summary-item row total2">
            <div className="col-8 col-md-9">Total</div>
            <div className="col-4 col-md-3">
              ${grandTotal ? grandTotal.split(".")[0] : "00"}.
              <span className="decimal">
                {grandTotal ? grandTotal.split(".")[1] : "00"}
              </span>
            </div>
          </div>

          {/* <button className="pay" onClick={()=>navigate('/checkout/payment')}>Pay Now</button> */}
          <button
            className="pay"
            onClick={setCarhPreHandler}
            disabled={
              grandTotal <= 0 ||
              !(state.userDetails.shippingAddress.length > 0) ||
              !(state.userDetails.billingAddress.length > 0)
            }
          >
            Pay via Card
          </button>

          <button
            className="pay payACH"
            onClick={createACHSourceHandler} // old direct bank transfer
            // onClick={openACHPage}  //plaid
            disabled={
              grandTotal <= 0 ||
              !(state.userDetails.shippingAddress.length > 0) ||
              !(state.userDetails.billingAddress.length > 0)
            }
          >
            Pay via ACH
          </button>
        </div>
      )}
      {display === "shippingAddresses" && (
        <AddressList
          address={
            state.userDetails && state.userDetails.shippingAddress
              ? state.userDetails.shippingAddress
              : []
          }
          setDisplay={setDisplay}
          displayScreen="addShipping"
          setAddressDetails={setAddressDetails}
          onBack={backClickHandler}
        />
      )}
      {display === "billingAddresses" && (
        <AddressList
          address={
            state.userDetails && state.userDetails.billingAddress
              ? state.userDetails.billingAddress
              : []
          }
          setDisplay={setDisplay}
          displayScreen="addBilling"
          setAddressDetails={setAddressDetails}
          onBack={backClickHandler}
        />
      )}
      {display === "addPD" && (
        <PersonalDetails setDisplay={setDisplay} onBack={backClickHandler} />
      )}
      {display === "addShipping" && (
        <AddressDetails
          setDisplay={setDisplay}
          addressDetails={addressDetails}
          states={states}
          display={display}
          onBack={backClickHandler}
        />
      )}
      {display === "addBilling" && (
        <AddressDetails
          setDisplay={setDisplay}
          addressDetails={addressDetails}
          states={states}
          display={display}
          onBack={backClickHandler}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, {
  getStatesHandler,
  updateUserDetailsHandler,
  transferACHHandler,
  purchaseProducts,
})(AllDetails);
