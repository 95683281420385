import React from 'react';
import Modal from 'react-modal';

const ModalTemplate = (props) => {

  const modalStyle = props.modalStyle

    return (
        <div>
          <Modal 
             isOpen={props.showModal}
             style={modalStyle}
             contentLabel="Minimal Modal Example"
             ariaHideApp={false}
             onRequestClose={props.onRequestClose}
          >
            {props.children}
          </Modal>
        </div>
      );
    }

export default ModalTemplate;