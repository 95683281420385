import React, { useState, useEffect } from "react";
import ChangeDetailsItem from "../change-details-item";
import "./style.scss";
import { Formik } from "formik";
import Input from "../../../../input";
import { connect } from "react-redux";
import { updateUserDetailsHandler } from "../../../../../actions/user/action";
import { updateUserHandler } from "../../../../../actions/brand/action";

const PersonalDetails = ({ setDisplay, state,updateUserDetailsHandler,updateUserHandler,onBack }) => {
  const [formikValues, setFormikvalues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [renderFormik, setRenderFormik] = useState(false);

  useEffect(() => {
    if (state && state.userDetails && state.userDetails.user) {
      let firstNameTemp = state.userDetails.user.name.split(" ")[0];
      let lastNameTemp = state.userDetails.user.name.split(" ")[1];
      setFormikvalues({
        firstName: firstNameTemp,
        lastName: lastNameTemp,
        phoneNumber: state.userDetails.user.phone,
        email: state.userDetails.user.email,
      });
    }
    setRenderFormik(true);
  }, [state]);

  return (
    <div className="all-items details">
      {renderFormik && (
        <Formik
          initialValues={{ ...formikValues }}
          enableReinitialze={true}
          validate={(values) => {
            const errors = {};

            if (!values.firstName) {
              errors.firstName = "Required";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = "Please enter Phone Number";
            } else if (!/^[0-9]{10}$/i.test(values.phoneNumber)) {
              errors.phoneNumber = "Invalid Phone Number";
            }
            if (!values.email) {
              errors.email = "Please enter Phone Number";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            updateUserHandler({...values,id:localStorage.getItem("userId")});

            let userDetailsTemp = { ...state };
            userDetailsTemp.userDetails.user.name = values.firstName+' '+values.lastName
            userDetailsTemp.userDetails.user.email =  values.email
            userDetailsTemp.userDetails.user.phone = values.phoneNumber
            updateUserDetailsHandler(userDetailsTemp);
            onBack();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="form-register mt_25_detail"
            >
              <>
                <div className="row">
                  <div className="col-6 input-container">
                    <div className="input-text">First Name</div>
                    <div className="details-border small">
                      <Input
                        name="firstName"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.firstName && touched.firstName && (
                      <p className="error-class">Please enter First Name</p>
                    )}
                  </div>
                  <div className="col-6 input-container">
                    <div className="input-text">Last Name</div>
                    <div className="details-border small">
                      <Input
                        name="lastName"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-12 input-container">
                    <div className="input-text">Phone Number</div>
                    <div className="details-border">
                      <Input
                        name="phoneNumber"
                        placeholder="type here"
                        className="input"
                        type="text"
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.phoneNumber && touched.phoneNumber && (
                      <p className="error-class">{errors.phoneNumber}</p>
                    )}
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-12 input-container">
                    <div className="input-text">Email Address</div>
                    <div className="details-border">
                      <Input
                        name="email"
                        placeholder="type here"
                        className="input"
                        type="text"
                        disabled={true}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.email && touched.email && (
                      <p className="error-class">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="done">
                  <button>Done!</button>
                </div>
              </>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, {updateUserDetailsHandler,updateUserHandler})(PersonalDetails);
