import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/cart-icon.json";
import Loader from "../../../common/Loader";
import { getUserDetailsHandler } from "../../../../actions/user/action";

import "./style.scss";
import AllDetails from "./all-details";
import { navigate } from "gatsby";
import Toaster from "../../../common/Toaster";

const CheckoutDetails = ({ getUserDetailsHandler, state }) => {
  const [loading, setLoading] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [back, setBack] = useState(0);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [refreshDetails, doRefreshDetails] = useState(0);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const backClickHandler = () => {
    setBack((prev) => prev + 1);
  };

  useEffect(async () => {
    setLoading(true);
    let uId = localStorage.getItem("userId");

    if (!uId || uId === null) {
      navigate("/onboarding/step-one");
    } else {
      let response = await getUserDetailsHandler(
        localStorage.getItem("userId")
      );
      setLoading(false);
      if (response.error) {
        if(response.errorData ==='Please authenticate'){
          setToastrMsg("Your session has expired. please login again!");
          doRefresh((prev) => prev + 1);
          setTimeout(() => {
            localStorage.clear();
          navigate("/login");
          }, 500);
        }
        else{
          setToastrMsg(response.errorData);
          doRefresh((prev) => prev + 1);
        }
      }
      // console.log(response)
    }
  }, [refreshDetails]);

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="checkout-details">
        <div className="card">
          <div className="cart-header">
            <span className="header-left" onClick={backClickHandler}>
              <img src="/images/common/left-arrow-blue2.svg" />
            </span>
            Checkout
          </div>
          <div className="cart-body">
            <div className="cart-body-left col-12 col-md-6">
              {!isMobile && (
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: 400, height: 400, margin: "auto" }}
                />
              )}
              {isMobile && (
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: 200, height: 200, margin: "auto" }}
                />
              )}
            </div>
            <div className="cart-body-right col-12 col-md-6">
              <div className="cart-blue">
                <AllDetails
                  backbuttonHandler={back}
                  onBackuttonClick={backClickHandler}
                  doRefreshDetails={doRefreshDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  state: state.userDetails,
});

export default connect(mapStateToProps, {
  getUserDetailsHandler,
})(CheckoutDetails);
