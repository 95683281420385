import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../../common/modal";
import "./style.scss";

const PaymentBankDetails = ({ showModal, changeModalState, achDetails,amount }) => {
  const [isMobile, setMobile] = useState(false);
  const [modalStyle, setmodalStyle] = useState({
    content: {
      background: "#FFF9F9",
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0.5px solid #ddbdbd",
      inset: "20%",
    },
    overlay: {
      zIndex: 2,
    },
  });

  useEffect(() => {
    if (window.innerWidth < 768) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 768 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setmodalStyle({
        ...modalStyle,
        content: {
          inset: "5%",
        },
      });
    }
  }, [isMobile]);

  return (
    <>
      <ModalTemplate showModal={showModal} modalStyle={modalStyle}>
        <div className="payment-details">
          <img
            className="waitlist-close"
            onClick={changeModalState}
            src="/images/common/custom-color-cross.svg"
          />

          {achDetails.ach_credit_transfer &&
            achDetails.ach_credit_transfer.routing_number && (
              <div className="pd-body">
                <span className="pd-heading">
                Please check your email for our account and routing number. 
                <br />
                <br />
                <span>
                Once transfer is made, order will begin.
                </span>
                
                </span>{" "}
                <br />
                <br />
                {/* <div className="row">
                  <div className="col-6 tr">Amount : </div>
                  <div className="col-6 tl ft-bold">
                    ${amount}
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-6 tr">Routing Number : </div>
                  <div className="col-6 tl ft-bold"> */}
                    {/* {achDetails.ach_credit_transfer.routing_number} */}
                    {/* 125109019
                   </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-6 tr">Account Number :</div>
                  <div className="col-6 tl ft-bold"> */}
                    {/* {achDetails.ach_credit_transfer.account_number} */}
                    {/* 875103024571
                  </div>
                </div> */}
              </div>
            )}

          <button className="pd-button" onClick={changeModalState}>
            Thank you
          </button>
        </div>
      </ModalTemplate>
    </>
  );
};

export default PaymentBankDetails;
