import React ,{useEffect, useState} from "react";
import "./style.scss";
import { connect } from "react-redux";
import { updateUserDetailsHandler } from "../../../../../actions/user/action";
import { deleteAddressHandler } from "../../../../../actions/brand/action";
import Loader from "../../../../common/Loader";

const ChangeDetailsItem = ({
  setDisplay,
  address,
  displayScreen,
  setAddressDetails,
  updateUserDetailsHandler,
  deleteAddressHandler,
  state
}) => {

  
  const [loading, setLoading] = useState(false);

  const updateHandler = async (id) => {
    
    setLoading(true)
    let userDetailsTemp = { ...state };
    if (userDetailsTemp.userDetails.billingAddress.find((e) => e._id === id)) {
      let tempBillingArray = userDetailsTemp.userDetails.billingAddress.map((e) => {
        if (e._id === id) {
          return {...e,display : true}
        } else {
          return {...e,display : false}
        }
      });
      userDetailsTemp.userDetails.billingAddress = [...tempBillingArray]
    }
    else if (userDetailsTemp.userDetails.shippingAddress.find((e) => e._id === id)) {
      let tempShippingArray = userDetailsTemp.userDetails.shippingAddress.map((e) => {
        if (e._id === id) {
          return {...e,display : true}
        } else {
          return {...e,display : false}
        }
      });
      userDetailsTemp.userDetails.shippingAddress = [...tempShippingArray]
    }
    await updateUserDetailsHandler(userDetailsTemp);
    
    setLoading(false)
  };


  const deleteAddress = async (id) => {
    setLoading(true)
    deleteAddressHandler({id:id});
    let userDetailsTemp = { ...state };
    if (userDetailsTemp.userDetails.billingAddress.find((e) => e._id === id)) {
      let tempBillingArray = userDetailsTemp.userDetails.billingAddress.filter((e) => e._id !== id);
      userDetailsTemp.userDetails.billingAddress = [...tempBillingArray]
    }
    else if (userDetailsTemp.userDetails.shippingAddress.find((e) => e._id === id)) {
      let tempShippingArray = userDetailsTemp.userDetails.shippingAddress.filter((e) => e._id !== id);
      userDetailsTemp.userDetails.shippingAddress = [...tempShippingArray]
    }
    
    await updateUserDetailsHandler(userDetailsTemp);
    setLoading(false)
  }

  return (
    <>
    
    
    <div className="change-details-item row">
    <Loader isLoading={loading} />
      <div
        className="col-1 select"
        onClick={updateHandler.bind(null, address._id)}
      >
        <div className={`circle ${address.display && "filled"}`}></div>
      </div>
      {address && (
        <div className="item-text col-8 col-lg-8">
          <span className="mt-span">
            {address.address} {address.suite && `Suite#${address.suite}`}
          </span>
          <span className="mt-span">
            {address.city} {address.state} {address.zip}
            {!address.default && !address.display && (
              <span 
              className="edit edit-ml"
              onClick={deleteAddress.bind(null, address._id)}
              >DELETE</span>
            )}
          </span>
        </div>
      )}
      <div className="col-3 col-lg-3 item-button">
        <span
          className="edit"
          onClick={() => {
            setDisplay(displayScreen);
            setAddressDetails(address);
          }}
        >
          EDIT
        </span>
        {/* <button onClick={setDisplay.bind(null,'change')}>CHANGE</button> */}
      </div>
    </div>

    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails
});

export default connect(mapStateToProps, {updateUserDetailsHandler,deleteAddressHandler })(ChangeDetailsItem);
