import React from "react";
import Modal from "react-modal";
import "./style.scss";

const Loader = (props) => {

    const modalStyle = {
        content: {
          background: "transparent",
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          inset: "20px",
          overflow: 'hidden',
        },
        overlay: {
          zIndex: 9,
          background: "#d3cdcd94",
        },
      }

  return (
    <div>
      <Modal
        isOpen={props.isLoading}
        style={modalStyle}
        contentLabel="Minimal Modal Example"
        ariaHideApp={false}
      >
        {
          <div className="loader-product">
            <img src="/images/common/loader.svg" />
          </div>
        }
      </Modal>
    </div>
  );
};

export default Loader;
