import React from 'react'
import "./style.scss";

const AllDetailsItem = ({line1,line2,line3,setDisplay,popup}) => {
  return (
    <div className='all-details-item row'>
      <div className='item-text col-8 col-lg-9'>
      <span className='item-heading'>{line1} <img src="/images/common/cart-item-tick.svg" /></span>
      <span className='mt-span'>{line2}</span>
      <span className='mt-span'>{line3}</span>
      </div>
      <div className='col-4 col-lg-3 item-button'>
        <button onClick={setDisplay.bind(null,popup)}>CHANGE</button>
      </div>
    </div>
  )
}

export default AllDetailsItem;